import React from 'react';

import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { generateStatsPayload } from '../helpers/stats';
import TournamentStatsTable from './TournamentStatsTable';
import { statsActions } from '../actions/stats.actions';
import { pointsActions } from '../actions/points.actions';
import { DateInput } from 'semantic-ui-calendar-react';
import Venues from './Venues';
import moment from 'moment';
import { tournamentsActions } from '../actions/tournaments.actions';
import { ledgerTotals } from '../helpers/ledger';
import TournamentResults from './TournamentResults';

class TournamentStats extends React.Component {
  state = {
    date: moment().format('DD-MM-YYYY'),
    venue: null,
    name: null,
    finalStats: [],
    isTournamentResultsModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.updateFinalStatsCallback = this.updateFinalStatsCallback.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let updatedState = {};
    if (props.finalStats !== state.finalStats) {
      updatedState['finalStats'] = props.finalStats;
    }
    if (props.tournamentDetails.name !== state.name) {
      updatedState['name'] = props.tournamentDetails.name;
    }
    if (props.tournamentDetails.date !== state.date) {
      updatedState['date'] = props.tournamentDetails.date;
    }
    if (props.tournamentDetails.venue !== state.venue) {
      updatedState['venue'] = props.tournamentDetails.venueid;
    }

    return updatedState;
  }

  updateFinalStatsCallback(finalStats) {
    this.setState({ finalStats: finalStats });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.finalStats.length > 0 &&
      prevState.finalStats.length !== this.state.finalStats.length
    ) {
      this.props.getPoints(this.state.finalStats.length);
    }
  }

  calculateNet(players, ledger, prizeAllocation) {
    const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;

    for (let idx in players) {
      let id = parseInt(idx) + 1; // nth place players prize allocation

      let positionPrize = 0;

      // Check if there's a prize allocation for this position
      if (id <= Object.keys(prizeAllocation).length) {
        positionPrize = (prizeAllocation[id].percentage / 100) * totalCashInPot;
      }

      players[idx].prize = Math.round(positionPrize);
      players[idx].profit = Math.round(positionPrize - players[idx].cash);
    }

    return [...players];
  }

  toggleResultsModal = () => {
    this.setState((prevState) => ({
      isTournamentResultsModalOpen: !prevState.isTournamentResultsModalOpen,
    }));
  };

  render() {
    const players = this.calculateNet(
      this.state.finalStats,
      this.props.ledger,
      this.props.prizeAllocation
    );

    return (
      <>
        <Modal
          open={this.props.gameEnded}
          size="fullscreen"
          className="players-modal modal-dimmer-bg-color"
        >
          <Modal.Header className="players-extras-modal-header">
            Final Stats
          </Modal.Header>
          <Modal.Content className="modal-bg-content-container">
            <Modal.Description>
              <Grid>
                <Grid.Row>
                  <Grid.Column className="twelve wide column">
                    <TournamentStatsTable
                      players={players}
                      updateFinalStatsCallback={this.updateFinalStatsCallback}
                    />
                  </Grid.Column>
                  <Grid.Column className="four wide column">
                    <Form>
                      <Form.Field>
                        <label className="set-white-color">Name</label>
                        <input
                          placeholder="Name"
                          value={this.state.name}
                          onChange={(e) => {
                            this.props.setTournamentName(e.target.value);
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label className="set-white-color">Date</label>
                        <DateInput
                          clearable
                          clearIcon={<Icon name="remove" color="black" />}
                          name="date"
                          value={this.state.date}
                          onChange={(a, { name, value }) =>
                            this.props.setTournamentDate(value)
                          }
                        />
                        <Form.Field>
                          <label className="set-white-color">Venue</label>
                          <Venues
                            value={this.state.venue}
                            onChange={(e, { value }) =>
                              this.props.setTournamentVenue(
                                e.target.textContent,
                                value
                              )
                            }
                          />
                        </Form.Field>
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Description>
            <Modal.Actions className="submit-stats-action-button">
              <Button
                content="Submit Stats"
                onClick={() => {
                  this.props.submitStats(
                    generateStatsPayload(
                      this.props.tournamentDetails.id,
                      this.state.date,
                      this.state.name,
                      this.state.venue,
                      this.state.finalStats
                    )
                  );
                  this.toggleResultsModal();
                  //this.props.setGameEnded(false, this.props.ledger);
                  //this.props.resetTournamentState(); // Reset the state
                }}
                positive
              />
            </Modal.Actions>
          </Modal.Content>
        </Modal>
        <TournamentResults
          open={this.state.isTournamentResultsModalOpen}
          onClose={this.toggleResultsModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ledger: state.ledger,
    tournamentPlayers: state.game.tournament.players,
    bustPlayers: state.game.tournament.bust,
    leaguePlayers: state.game.players,
    gameEnded: state.game.tournament.gameEnded,
    tournamentDetails: state.game.tournament.details,
    finalStats: state.game.tournament.finalStats,
    prizeAllocation: state.game.tournament.pot.prizeAllocation,
    points: state.points, // Mapping points to props
  };
};

export default connect(mapStateToProps, {
  submitStats: statsActions.submitStats,
  setGameEnded: tournamentsActions.setGameEnded,
  setTournamentName: tournamentsActions.setTournamentName,
  setTournamentDate: tournamentsActions.setTournamentDate,
  setTournamentVenue: tournamentsActions.setTournamentVenue,
  setTournamentFinalStats: tournamentsActions.setTournamentFinalStats,
  resetTournamentState: tournamentsActions.resetTournamentState,
  getPoints: pointsActions.getPoints, // Dispatching getPoints action
})(TournamentStats);
