import React from 'react';

import { Button, Grid, Modal } from 'semantic-ui-react';

import TournamentPlayer from './TournamentPlayer';
import PlayerSelector from './PlayerSelector';
import { connect } from 'react-redux';

const Players = ({ leaguePlayers, tournamentPlayers }) => {
  const idToPlayer = (id) => {
    let playerMap = {};
    leaguePlayers.forEach(function (item) {
      playerMap[item.id] = item;
    });
    return playerMap[parseInt(id)];
  };

  const renderTableData = () => {
    return tournamentPlayers.map((playerId) => {
      const { name, id, photo } = idToPlayer(playerId);
      return <TournamentPlayer name={name} key={id} id={id} photo={photo} />;
    });
  };

  return (
    <Modal
      closeIcon
      trigger={<Button>Players</Button>}
      size="fullscreen"
      centered={false}
      className="players-modal modal-dimmer-bg-color"
    >
      <Modal.Header className="players-modal-header">Players</Modal.Header>
      <PlayerSelector />
      <Modal.Content className="players-modal-content modal-bg-content-container">
        <Grid divided="vertically">
          <Grid.Row>
            <Grid.Column className="six wide column">
              {' '}
              <h2 className="players-modal-secodary-headers">Name</h2>{' '}
            </Grid.Column>
            <Grid.Column className="four wide column">
              {' '}
              <h2 className="players-modal-secodary-headers">Cash</h2>{' '}
            </Grid.Column>
            <Grid.Column className="four wide column">
              <h2 className="players-modal-secodary-headers">Chips</h2>{' '}
            </Grid.Column>
            <Grid.Column className="two wide column"> </Grid.Column>
          </Grid.Row>

          {renderTableData()}
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    leaguePlayers: state.game.players,
    tournamentPlayers: state.game.tournament.players,
    ledger: state.ledger,
  };
};

export default connect(mapStateToProps, {})(Players);
