import React from 'react';
import {
  Button,
  Form,
  Message,
  Dropdown,
  Modal,
  Radio,
  Popup,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { potActions } from '../actions/pot.actions';
import { ledgerTotals } from '../helpers/ledger';
import {
  calculatePrizeWithRounding,
  calculateTotalAllocatedCash,
  calculateTotalAllocatedPercentage,
  ordinalSuffixOf,
} from '../helpers/prizeUtils';
import { styled } from 'styled-components';
import { prizesActions } from '../actions/prizes.actions';

const money = 'money';
const percentage = 'percentage';

const PrizesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getPrizeStructureOptions = (prizes) =>
  prizes.map(({ id, name }) => ({
    key: id,
    text: name,
    value: id,
  }));

class Prizes extends React.Component {
  constructor(props) {
    super(props);

    this.updatePrizeAllocations = this.updatePrizeAllocations.bind(this);
  }

  state = {
    showModal: false,
    error: false,
    prizeAllocation: this.props.pot.prizeAllocation,
    prizeStructure: {},
    totalAllocatedCash: 0,
    totalAllocatedPercentage: 0,
    cashRounding: 1,
    selectedPrizeStructureId: this.props.pot.selectedPrizeStructureId || '', // Initialize from props or empty
  };

  componentDidMount() {
    const { pot, ledger } = this.props;
    const totalRemainingCash = ledgerTotals(ledger).totalCash;

    this.setState({
      prizeAllocation: pot.prizeAllocation,
      totalRemainingCash: totalRemainingCash,
      cashRounding: pot.cashRounding,
      selectedPrizeStructureId: pot.selectedPrizeStructureId || '', // Fallback to empty string if not defined
    });
  }

  updatePrizeAllocations(position, value, field, cashRounding = 1) {
    const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;

    let cash, percentage;

    if (field === 'cash') {
      cash = parseInt(value, 10);
      percentage = (parseInt(value, 10) / totalCashInPot) * 100;
    } else {
      cash = calculatePrizeWithRounding(
        parseInt(value, 10),
        totalCashInPot,
        cashRounding
      );
      percentage = parseInt(value, 10);
    }

    // Use current state prizeAllocation, not props
    const currentPrizeAllocation = this.state.prizeAllocation;

    const updatedPrizeAllocation = {
      ...currentPrizeAllocation,
      [position]: {
        cash: cash,
        percentage: percentage,
      },
    };

    const totalAllocatedCash = calculateTotalAllocatedCash(
      updatedPrizeAllocation
    );
    const totalRemainingCash = totalCashInPot - totalAllocatedCash;

    const totalAllocatedPercentage = calculateTotalAllocatedPercentage(
      updatedPrizeAllocation
    );

    this.props.updatePrizeAllocations(updatedPrizeAllocation, 1);

    this.setState({
      prizeAllocation: updatedPrizeAllocation,
      totalAllocatedCash: totalAllocatedCash,
      totalAllocatedPercentage: totalAllocatedPercentage,
      error: totalRemainingCash < 0 || totalAllocatedPercentage > 100,
    });
  }

  closeModal = () => {
    this.setState({ showModal: this.state.error });
  };

  save = () => {
    this.props.updatePrizeAllocations(
      this.state.prizeAllocation,
      this.state.cashRounding
    );
    this.closeModal();
  };

  recalculateRounding(cashRounding) {
    const newPrizeAllocation = {};
    const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;

    Object.keys(this.state.prizeAllocation).forEach((key) => {
      const percentage = this.state.prizeAllocation[key].percentage;
      newPrizeAllocation[key] = {
        cash: calculatePrizeWithRounding(
          parseInt(percentage),
          totalCashInPot,
          cashRounding
        ),
        percentage: percentage,
      };
    });

    this.setState({ prizeAllocation: newPrizeAllocation });
  }

  handleDropdownChange = (_, { value }) => {
    const selectedPrizeStructure = this.props.prizes.find(
      ({ id }) => id === value
    );

    if (selectedPrizeStructure) {
      const { structure, rounding } = selectedPrizeStructure;
      const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;
      const newPrizeAllocation = {};

      // Calculate prize allocation based on the selected structure and rounding
      Object.keys(structure).forEach((position) => {
        const percentage = structure[position];
        const cash = calculatePrizeWithRounding(
          percentage,
          totalCashInPot,
          rounding
        );

        newPrizeAllocation[position] = {
          cash: cash,
          percentage: percentage,
        };
      });

      // Update local state with the selected prize structure, rounding, and prize allocation
      this.setState({
        prizeStructure: structure,
        cashRounding: rounding,
        prizeAllocation: newPrizeAllocation,
        selectedPrizeStructureId: value, // Store the selected structure ID
      });
    }
  };

  generatePrizes() {
    const prizePlaces = [];

    if (this.state.prizeStructure === undefined) {
      return null;
    }

    for (let i in this.state.prizeAllocation) {
      prizePlaces.push(
        <div key={i}>
          <Form.Field className="prizes-screen-form-content">
            <div>
              <label>{ordinalSuffixOf(i)}</label>
            </div>
            <div>
              <input
                placeholder="Money"
                type="number"
                min={0}
                disabled={this.props.pot.cashMode === false}
                className={
                  this.props.pot.cashMode === true ? 'active-input' : ''
                }
                value={
                  this.state.prizeAllocation[i].cash || 0 // Use the state directly here
                }
                onChange={(event) =>
                  this.updatePrizeAllocations(
                    i,
                    parseInt(event.target.value),
                    'cash',
                    this.state.cashRounding
                  )
                }
              />
            </div>
            <div>
              <input
                placeholder="Percentage"
                type="number"
                min={0}
                disabled={this.props.pot.cashMode === true}
                value={this.state.prizeAllocation[i].percentage || 0} // Use the state directly here
                className={
                  this.props.pot.cashMode === false ? 'active-input' : ''
                }
                onChange={(event) =>
                  this.updatePrizeAllocations(
                    i,
                    parseInt(event.target.value),
                    'percentage',
                    this.state.cashRounding
                  )
                }
              />
            </div>
          </Form.Field>
        </div>
      );
    }

    return prizePlaces;
  }

  render() {
    const { showModal, prizeAllocation, error } = this.state;
    return (
      <Modal
        closeIcon
        trigger={
          <Button onClick={() => this.setState({ showModal: true })}>
            Prizes
          </Button>
        }
        size="fullscreen"
        centered={false}
        open={showModal}
        onClose={this.closeModal}
        className="modal-dimmer-bg-color"
      >
        <Modal.Header className="modal-prizes-screen-header">
          <PrizesHeader>
            <h1 className="round-header">Prizes</h1>
            <label className="round-header">Select Prize Structure: </label>
            <Dropdown
              selection
              options={getPrizeStructureOptions(this.props.prizes) || []}
              onChange={this.handleDropdownChange}
              value={this.state.selectedPrizeStructureId || ''} // Use state value here
              disabled={!this.props.prizes.length}
              placeholder={'Select a saved prize structure'}
            />
            <Popup
              trigger={
                <button
                  className="ui button rounds-action-buttons"
                  onClick={() => {
                    this.setState({ saveConfirmOpen: true });
                  }}
                >
                  Save
                </button>
              }
              on="click"
              open={this.state.saveConfirmOpen}
              onClose={this.handleClose}
              onOpen={this.handleOpen}
              position="bottom right"
              className="save-blinds-popup"
            >
              <Popup.Header>
                <span>Save Blinds</span>
                <Icon
                  className="close"
                  link
                  icon="close"
                  onClick={() => this.setState({ saveConfirmOpen: false })}
                />
              </Popup.Header>
              <Popup.Content>
                <p>Please give this prize structure a name.</p>
                <div className="ui input">
                  <input
                    type="text"
                    placeholder="Name..."
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
                <Button
                  color="green"
                  content="Save"
                  icon="save"
                  onClick={async () => {
                    if (this.state.name === null) {
                      return;
                    }
                    try {
                      const newPrizes = await this.props.addPrizesStructure(
                        this.state.name,
                        this.state.cashRounding,
                        this.state.prizeAllocation
                      );

                      await new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                        }, 500);
                      });

                      await this.props.getPrizes();
                      this.setState({
                        selectedPrizeStructureId: newPrizes?.id,
                        saveConfirmOpen: false,
                      });
                    } catch (error) {
                      console.error('Error adding prizes:', error); // Handle the error appropriately
                    }
                  }}
                />
              </Popup.Content>
            </Popup>
          </PrizesHeader>
        </Modal.Header>
        <Modal.Content className="modal-bg-content-container modal-prizes-screen-content">
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <div>
                  <h3>
                    Used: {calculateTotalAllocatedCash(prizeAllocation)} of{' '}
                    {ledgerTotals(this.props.ledger).totalCash} of cash Used:{' '}
                    {Math.round(
                      calculateTotalAllocatedPercentage(prizeAllocation) * 100
                    ) / 100}{' '}
                    of 100%
                  </h3>
                  <Form.Group widths="equal">
                    {this.generatePrizes(0)}
                  </Form.Group>
                  {error && (
                    <Message negative>
                      <Message.Header>Error</Message.Header>
                      <p>
                        You have allocated more prize money than is in the prize
                        pool.
                      </p>
                    </Message>
                  )}
                </div>
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <Radio
                    label="Money"
                    name="radioGroup"
                    value={money}
                    checked={this.props.pot.cashMode === true}
                    onChange={() => this.props.updatePrizeAllocationType(true)}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Percentage"
                    name="radioGroup"
                    value={percentage}
                    checked={this.props.pot.cashMode === false}
                    onChange={() => this.props.updatePrizeAllocationType(false)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <label className="rounding-field">Rounding</label>
                  <input
                    className="rounding-field-input"
                    placeholder="1"
                    type="number"
                    value={this.state.cashRounding}
                    min={1}
                    max={100}
                    step={1}
                    onChange={(e) => {
                      const newRounding = e.target.value
                        ? parseInt(e.target.value)
                        : 1; // Default to 0 if input is empty
                      this.setState({ cashRounding: newRounding });
                      this.recalculateRounding(newRounding);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions className="modal-prizes-screen-save-button">
          <Button
            onClick={() => {
              this.save();
            }}
            disabled={this.state.error}
            positive
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ game, ledger }) => ({
  pot: game.tournament.pot,
  ledger: ledger,
  prizes: game.prizes,
});

export default connect(mapStateToProps, {
  updatePrizeAllocationType: potActions.updatePrizeAllocationType,
  updatePrizeAllocations: potActions.updatePrizeAllocations,
  getPrizes: prizesActions.getPrizes,
  addPrizesStructure: prizesActions.addPrizesStructure,
})(Prizes);
