import React from 'react';
import { Button, Dropdown, Input, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tournamentsActions } from '../actions/tournaments.actions';
import { playersActions } from '../actions/players.actions';
import CreatePlayer from './CreatePlayer';

class PlayerSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      cash: this.props.tournamentDetails.buyin_cash,
      chips: this.props.tournamentDetails.buyin_chips,
      isCreatePlayerModalOpen: false,
    };
  }

  addPlayerToTournamentRequest() {
    if (this.state.player === null) {
      alert('No player selected');
      return;
    }

    this.props.addPlayerToTournament(
      this.state.player,
      this.props.tournamentId,
      this.state.cash,
      this.state.chips
    );
    this.setState({ player: null });

    this.props.setDefaultBuyin(this.state.chips, this.state.cash);
  }

  playerOptions(players) {
    let options = [];

    this.props.leaguePlayers.forEach(function (item) {
      const name = item.name;
      const _id = item.id;
      const photo = item.photo;

      if (players.includes(_id) === false) {
        options.push({
          key: _id,
          value: _id,
          text: name,
          image: { avatar: true, src: photo },
        });
      }
    });

    return options;
  }

  toggleCreatePlayerModal = () => {
    this.setState((prevState) => ({
      isCreatePlayerModalOpen: !prevState.isCreatePlayerModalOpen,
    }));
  };

  closeCreatePlayerModal = () => {
    this.setState({ isCreatePlayerModalOpen: false });
    this.props.getPlayers();
  };

  render() {
    return (
      <div className="players-table">
        <div className="dropdown-players-menu">
          <Dropdown
            className="players-table-dropdown"
            placeholder="Add player to game"
            search
            selection
            options={this.playerOptions(this.props.tournamentPlayers)}
            onChange={(e, d) => this.setState({ player: d.value })}
          />
        </div>
        <div className="players-table-item">
          <Label className="players-label">Cash</Label>
          <Input
            value={this.state.cash}
            onChange={(e, d) => {
              localStorage.setItem('cash', d.value);
              return !isNaN(d.value) && d.value !== ''
                ? this.setState({ cash: parseInt(d.value) })
                : 0;
            }}
          />
        </div>
        <div className="players-table-item">
          <Label className="players-label">Chips</Label>
          <Input
            value={this.state.chips}
            onChange={(e, d) => {
              return !isNaN(d.value) && d.value !== ''
                ? this.setState({ chips: parseInt(d.value) })
                : 0;
            }}
          />
        </div>
        <div className="add-players-button">
          <Button onClick={this.toggleCreatePlayerModal}>Create Player</Button>
        </div>
        <div className="add-players-button">
          <Button
            onClick={() => {
              this.addPlayerToTournamentRequest(this.state.player);
            }}
          >
            Add Player
          </Button>
        </div>

        {/* Include the CreatePlayer modal and control its visibility */}
        <CreatePlayer
          open={this.state.isCreatePlayerModalOpen}
          onClose={this.closeCreatePlayerModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ game, ledger }) => ({
  leaguePlayers: game.players,
  tournamentId: game.tournament.details.id,
  tournamentPlayers: game.tournament.players,
  tournamentDetails: game.tournament.details,
  ledger: ledger,
  pot: game.tournament.pot,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addPlayerToTournament: tournamentsActions.addPlayerToTournament,
      setDefaultBuyin: playersActions.setDefaultBuyin,
      getPlayers: playersActions.getPlayers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlayerSelector);
