import AuthenticatedEndpoint from '../helpers/api';

function getPrizes() {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: `getprizes`,
    },
  }).then((res) => {
    return res.data.result;
  });
}

function addPrizesStructure(
  prizesStructureName,
  cashRounding,
  prizesStructure
) {
  if (!prizesStructure || typeof prizesStructure !== 'object') {
    throw new Error('Invalid prize structure');
  }

  const transformedStructure = {};

  // Iterate over the keys in prizesStructure and round the percentages
  Object.keys(prizesStructure).forEach((key) => {
    const percentage = prizesStructure[key].percentage || 0;
    transformedStructure[key] = parseFloat(percentage.toFixed(cashRounding));
  });

  // The new payload structure
  const payload = {
    name: prizesStructureName, // Corresponds to the "name" field
    rounding: cashRounding.toString(), // Use the passed cashRounding value
    structure: transformedStructure, // Transformed structure with rounded values
  };

  // Use the correct transformed payload in the API call
  return AuthenticatedEndpoint.post(
    null,
    payload, // Use the transformed payload
    {
      params: {
        op: `prizesadd`,
      },
    }
  ).then((res) => {
    return res.data;
  });
}

export const prizesServices = {
  getPrizes,
  addPrizesStructure,
};
